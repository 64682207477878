const Button = (props) => {
  const { className = "", type = "button", children, ...rest } = props;

  return (
    <>
      <button type={type} className={`${className}`} {...rest}>
        {children}
      </button>
    </>
  );
};

export default Button;
