import React, { useEffect, useState } from "react";
import Button from "../../ui/elements/Button";
import { Icon } from "../../ui";
import { chooseData, servicesData } from "./mock";
import Footer from "../footer/Footer";

const Home = () => {
  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  const [activeSection, setActiveSection] = useState("  ");

  const handleLinkClick = (link) => {
    console.log(link,"ooooo");
    setActiveLink(link);
    setActiveSection(link);
    scrollToSection(link);
    closeNavbar();
  };
  
  const scrollToSection = (div) => {
    const section = document.getElementById(div);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll("section");

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop - 180 && 
          scrollPosition < sectionTop + sectionHeight - 50 
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      console.log(window.location.hash);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };

  return (
    <>
      {/* NAVBAR */}
      <div className="nav-bg sticky-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light self-nav">
            <div className="container-fluid justify-content-between ">
              <div>
                <a className="navbar-brand" href="#home">
                  <Icon name="Logo" className="" />
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleNavbar}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse justify-content-center position-relative ${
                  isNavbarOpen ? "show" : ""
                }`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li
                    className={`nav-item ${
                      activeSection === "home" ? "active" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      aria-current="page"
                      onClick={() => {
                        closeNavbar();
                        handleLinkClick("home");
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li
                    className={`nav-item ${
                      activeSection === "about" ? "active" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => {
                        closeNavbar();
                        handleLinkClick("about");
                      }}
                    >
                      About Us
                    </a>
                  </li>
                  <li
                    className={`nav-item ${
                      activeSection === "services" ? "active" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => {
                        closeNavbar();
                        handleLinkClick("services");
                      }}
                    >
                      Services
                    </a>
                  </li>
                  <li
                    className={`nav-item ${
                      activeSection === "whychoos" ? "active" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => {
                        closeNavbar();
                        handleLinkClick("whychoos");
                      }}
                    >
                      Why Choose Us ?
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#contact"
                      onClick={closeNavbar}
                    >
                      Contact Us
                    </a>
                  </li> */}
                </ul>
                {windowWidth < 991 ? (
                  <form className="navbtn">
                    <a
                      type="submit"
                      className="yellowbtn"
                      href="mailto:sales@enigmastake.com"
                    >
                      Contact US
                    </a>
                  </form>
                ) : (
                  ""
                )}
              </div>
              {windowWidth > 991 ? (
                <form className="mybtn">
                  <a
                    type="submit"
                    className="yellowbtn"
                    href="mailto:sales@enigmastake.com"
                  >
                    Contact US
                  </a>
                </form>
              ) : (
                ""
              )}
            </div>
          </nav>
        </div>
      </div>

      <section id="home">
        <div className="blackbg position-relative" >
          <div className="container">
            <div className="hero-sec">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-6  my-2">
                  <h4>
                    <span>Best i-gaming Service provider</span>
                  </h4>
                  <h1>
                    Welcome to <span>Enigmastake </span> - Your Premier iGaming
                    Software & Services Provider ⭐️
                  </h1>
                  <p>
                    A global leader in iGaming software and services. With a
                    relentless commitment to innovation and excellence, we
                    empower businesses to thrive in the dynamic world of online
                    gaming. Our team of experts combines cutting-edge technology
                    with industry insights to deliver comprehensive solutions
                    tailored to your unique needs.
                  </p>
                  <div className="twobutton">
                    <a href="#contact" className="yellowbtn">
                      Request a Demo
                    </a>
                    <a href="#contact" className="greenbtn">
                      Know More
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 my-2">
                  <div className="heroimg">
                    <Icon name="Heroimg" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center "></div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="pagepadding">
          <section id="about">
            <div className="feature-sec" >
              <div className="row align-items-center my-2">
                <div className="col-lg-6 my-2">
                  <div className="keybox-img">
                    <Icon name="FullyAuto" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="keybox-sec">
                    <h6>About US</h6>
                    <Icon name="Line" className="img-fluid" />
                    <p>
                      At Enigmastake, we pride ourselves on being at the
                      forefront of the iGaming industry. With a global presence,
                      we bring together a team of passionate professionals
                      dedicated to revolutionizing the gaming experience. Our
                      journey is marked by a relentless pursuit of excellence,
                      fueled by a commitment to innovation and customer
                      satisfaction.
                    </p>
                    <p>
                      We leverage our extensive industry knowledge and technical
                      expertise to provide unparalleled iGaming software and
                      services. From designing intuitive user interfaces to
                      implementing robust backend systems, we ensure that our
                      solutions are not just functional but also elevate the
                      user experience.
                    </p>
                    <a href="#contact" className="blackbtn">
                      Know More{" "}
                      <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="services">
            <div >
              <div className="row">
                <div className="col-lg-10 col-md-10 ">
                  <div className="product-text">
                    <h4>Custom Service provider</h4>
                    <Icon name="Line" className="img-fluid" />
                    <p>
                      Welcome to Enigmastake, where innovation meets excellence
                      in iGaming solutions. As a leading custom iGaming software
                      provider, we pride ourselves on delivering cutting-edge
                      solutions tailored to meet the unique needs of our clients
                      in the dynamic online gaming industry.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between ">
                <div className="col-lg-8 col-md-12 my-2">
                  <div className="product-items">
                    <h5>iGaming Websites</h5>
                    <p>
                      Elevate your online gaming experience with our custom
                      iGaming websites. We design and develop visually stunning,
                      user-friendly platforms that captivate players and drive
                      engagement.
                    </p>
                    <div className="producticon">
                      <Icon name="ProductItemfour" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 my-2">
                  <div className="product-items">
                    <h5>Panel With Insights</h5>
                    <p>
                      Empower your business with intuitive control panels for
                      real-time insights.
                    </p>
                    <div className="producticon">
                      <Icon name="ProductItemFive" className="img-fluid" />
                    </div>
                  </div>
                </div>
                {chooseData.map((item, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-6 my-2">
                    <div className="product-items">
                      <h5>{item?.title}</h5>
                      <p>{item?.description}</p>
                      <div className="producticon">
                        <Icon name={item?.icon} className="img-fluid" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* <div className="feature-sec">
              <div className="productsec" >
                <div className="row">
                  <div className="col-lg-10 col-md-10">
                    <div className="product-text">
                      <h4>
                        Accept more payment options and accelerate your revenue.
                      </h4>
                      <Icon name="Line" className="img-fluid" />
                      <p>
                        Having 10+years of experience in our feathers has enabled
                        us to get connected with some of the most reliable &
                        renowned data feed as well as payment gateway providers in
                        the industry
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div>
                      <button href="#contact" className="blackbtn">
                        Know More{" "}
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="acceptmain">
                  <div className="row">
                    {boxLogoData.map((item, index) => (
                      <div className="col-lg-2 col-md-3 col-sm-4 col-6 my-2">
                        <div className="logobox">
                          <Icon name={item?.icon} className="img-fluid" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div> */}

          {/* Crafting sec */}
          <section id="whychoos" >
            <div className="feature-sec">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-8 col-sm-10 text-center">
                  <div className="product-text">
                    <h4>Why Choose Us ? </h4>
                    <Icon name="Line" className="img-fluid" />
                    <p>
                      Transforming concepts into immersive iGaming experiences
                      tailored just for you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between ">
                {servicesData.map((item, index) => (
                  <div className="col-lg-4 col-md-6 serviec-spacing">
                    <div className="serive-items">
                      <div className="seriveicon">
                        <Icon name={item?.icon} className="img-fluid" />
                      </div>
                      <h5>{item?.title}</h5>
                      <p>{item?.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Take sec */}
          {/* <div className="feature-sec">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-8 col-sm-10 text-center">
                <div className="product-text">
                  <h4>Take a look at the latest Blog from</h4>
                  <Icon name="Line" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row justify-content-between ">
              {takeData.map((item, index) => (
                <div className="col-lg-4 col-md-6 my-2">
                  <div className="take-items">
                    <div className="takeicon">
                      <Icon name={item?.icon} className="img-fluid" />
                    </div>
                    <h5>
                      <span>{item?.title}</span>
                    </h5>
                    <p>{item?.description}</p>
                    <div className="clientmain">
                      <Icon name={item?.Client} className="img-fluid" />
                      <p>{item?.name}</p>
                      <span>{item?.date}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className="trustsec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-8 col-sm-10 text-center">
              <div className="product-text">
                <h4>Trusted by 300+ companies around the world</h4>
                <Icon name="Line" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row justify-content-between ">
            {trustedClient.map((item, index) => (
              <div className="col-lg-4 col-md-6 my-2">
                <div className="trust-items text-center">
                  <div className="trsuz">
                    {Array.from({ length: item.star }, (_, i) => (
                      <i key={i} className="fa-solid fa-star"></i>
                    ))}
                    <div className="trusticon">
                      <Icon name={item?.icon} className="img-fluid" />
                    </div>
                    <h4>{item?.title}</h4>
                    <h6>{item?.name}</h6>
                    <p>"{item?.description}"</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* <div className="accordsec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-8 col-sm-10 text-center">
              <div className="product-text">
                <p>Common Questions</p>
                <h4>Frequently Asked Questions</h4>
                <Icon name="Line" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="accordion accordion-flush selfaccord"
                id="accordionFlushExample"
              >
                {accordionFullData.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={item.id}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        {item.title}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={item.id}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{item.content}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="accordion accordion-flush selfaccord"
                id="accordionFlushExample"
              >
                {accordionFullDataTwo.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={item.id}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${item.id}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${item.id}`}
                      >
                        {item.title}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${item.id}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={item.id}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{item.content}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <section id="contact">
        <div className="bgaboveimg letsmain">
          <div className="container">
            <div className="trymain">
              <div className="row justify-content-between align-items-center  ">
                <div className="col-lg-6 col-md-10">
                  <div className="road-heading">
                    <h5>Let’s start discussion for enigmastake services</h5>
                    <p>
                      Ready to elevate your iGaming experience? Contact
                      Enigmastake today. Let's embark on a journey of innovation
                      and success together.
                    </p>
                    <div className="contactbtn">
                      <Button
                        className="blackbtn"
                        // onClick={() => navigate("/contact")}
                      >
                        Get started for free
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="letsimg">
                    <Icon name="LetsImg" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
