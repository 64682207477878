import React from "react";
import { Icon } from "../../ui";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <a href="#home">
                    <Icon name="Logo" className="img-fluid mb-3" />
                  </a>
                  <p>
                    Embark on an epic journey with Enigmastake, leveling up your
                    gaming experience! Our focus meets gaming requirements,
                    enhancing your adventure
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Quick Links</h6>
                  <p>
                    <a href="#home">Home</a>
                  </p>
                  <p>
                    <a href="#about">About Us</a>
                  </p>
                  <p>
                    <a href="#services">Services</a>
                  </p>
                  <p>
                    <a href="#whychoos">Why Choose Us ? </a>
                  </p>
                  {/* <p>
                    <a href="#contact">Contact Us</a>
                  </p> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Services</h6>
                  <p>
                    <a href="#services">iGaming Websitesn</a>
                  </p>
                  <p>
                    <a href="#services">Control Panel</a>
                  </p>
                  <p>
                    <a href="#services">Business Management</a>
                  </p>
                  <p>
                    <a href="#services">Payment Gateway</a>
                  </p>
                  <p>
                    <a href="#services">Support</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <h6>Contact Us</h6>
                  <a href="mailto:sales@enigmastake.com">
                    <div className="contact">
                      <i class="fa-solid fa-envelope"></i>
                      <p className="mb-0">sales@enigmastake.com</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container copy-right">
          <div className="">
            <p>iGaming @2024</p>
          </div>
        </div> */}
      </footer>
    </>
  );
};

export default Footer;
