export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Business Management",
    description:
      "Our business management solutions simplify complex calculations and outcomes. Stay ahead of the curve with tools that streamline financial processes and enhance decision-making.",
  },
  {
    icon: "ProductItemtwo",
    title: "Payment Gateway",
    description:
      "Optimize iGaming transactions with Enigmastake's secure gateway, supporting traditional methods for smooth, trustworthy financial interactions. Elevate your iGaming venture with us!",
  },
  {
    icon: "ProductItemthree",
    title: "Support",
    description:
      "Rely on Enigmastake's 24/7 support for a seamless customer experience. Our dedicated team is here to assist with inquiries and technical issues, ensuring uninterrupted operations for your iGaming platform.",
  },
];

export const boxLogoData = [
  {
    icon: "BoxLogo1",
  },
  {
    icon: "BoxLogo2",
  },
  {
    icon: "BoxLogo3",
  },
  {
    icon: "BoxLogo4",
  },
  {
    icon: "BoxLogo5",
  },
  {
    icon: "BoxLogo1",
  },
  {
    icon: "BoxLogo2",
  },
  {
    icon: "BoxLogo3",
  },
  {
    icon: "BoxLogo4",
  },
  {
    icon: "BoxLogo5",
  },
];

export const portfolioData = [
  {
    title: "Effortless Integration",
    description:
      "Simplify your transactions by seamlessly utilizing popular apps such as PhonePe, GooglePay, and PayTm. Our fully automated system ensures a smooth payment experience, making your transactions hassle-free.",
  },
  {
    title: "Swift UPI/UTR Transactions",
    description:
      "Experience the speed and accuracy of transactions by inputting your UTR (Unique Transaction Reference) for swift processing. Our platform is optimized for quick and precise UPI transactions, providing you with a seamless and efficient experience.",
  },
];

export const portfolioDataTwo = [
  {
    title: "Quick Deposits with IMPS",
    description:
      "Enjoy the convenience of rapid deposits through IMPS. Our platform offers added flexibility and ensures prompt processing for all your transactions, making your financial interactions more convenient and efficient.",
  },
  {
    title: "QR Codes for Easy Payments",
    description:
      "Simplify your payment process by scanning our QR codes. Effortlessly make payments through your preferred apps with just a few clicks, ensuring a smooth and secure transaction experience tailored to your convenience.",
  },
];

export const servicesData = [
  {
    icon: "ServicesOne",
    title: "Global Presence, Local Excellence",
    description:
      "Enigmastake combines a global presence with a local understanding of the iGaming landscape. We understand the nuances of different markets, ensuring that our solutions resonate with diverse audiences worldwide.",
  },
  {
    icon: "ServicesTwo",
    title: "Innovative Solutions",
    description:
      "Stay ahead in the fast-evolving iGaming industry with our innovative solutions. We provide a comprehensive platform with versatile development tools, enabling you to simplify coding, testing, and deployment for a seamless development workflow.",
  },
  {
    icon: "ServicesThree",
    title: "Technical Advantages",
    description:
      "Versatile Development Tools: Access a range of powerful tools designed to simplify coding, testing, and deployment, ensuring a smooth development workflow.",
  },
  {
    icon: "Servicesfour",
    title: "Broad Compatibility",
    description:
      "Our platform is compatible with various operating systems and platforms, offering developers the flexibility to work with their preferred technology stack.",
  },
  {
    icon: "Servicesfive",
    title: "User-Friendly Experience",
    description:
      "Intuitive User Interface: Our platform prioritizes a user-friendly interface, reducing the learning curve for developers and promoting productivity.",
  },
  {
    icon: "Servicessix",
    title: "Scalability and Performance",
    description:
      "With a focus on scalability, our solution ensures optimal performance for projects of all sizes.",
  },
];

export const takeData = [
  {
    icon: "Take1",
    Client: "Client1",
    name: "Marvin McKinney",
    date: "February 15, 2024",
    title: "Fully Responsive",
    description:
      "Lorem ipsum dolor sit amet consectet piscing elit, sed do eiusmod.",
  },
  {
    icon: "Take2",
    Client: "Client2",
    name: "Robert Fox",
    date: "February 15, 2024",
    title: "Product Engineering",
    description:
      "Lorem ipsum dolor sit amet consectet piscing elit, sed do eiusmod.",
  },
  {
    icon: "Take3",
    Client: "Client3",
    name: "Marvin McKinney",
    date: "February 1, 2024",
    title: "Mobility Solution",
    description:
      "Lorem ipsum dolor sit amet consectet piscing elit, sed do eiusmod.",
  },
];

export const trustedClient = [
  {
    star: 5,
    icon: "Trust1",
    name: "Marvin McKinney",
    title: "Fully Responsive",
    description:
      "Lorem ipsum dolor sit amet consectet piscing elit, sed do eiusmod.",
  },
  {
    star: 5,
    icon: "Trust2",
    name: "Robert Fox",
    title: "Product Engineering",
    description:
      "Lorem ipsum dolor sit amet consectet piscing elit, sed do eiusmod.",
  },
  {
    star: 5,
    icon: "Trust3",
    name: "Marvin McKinney",
    title: "Mobility Solution",
    description:
      "Lorem ipsum dolor sit amet consectet piscing elit, sed do eiusmod.",
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What kind of data can I see in enigmastake?",
    content:
      "Getting started is easy! Simply create an account, configure your settings, and you'll be ready to enjoy hassle-free payments.",
  },
  {
    id: "flush-headingTwo",
    title: "What kind of data can I see in enigmastake?",
    content:
      "PGmine supports a variety of payment options, including UPI, UTR, IMPS, and cryptocurrencies, to cater to your specific needs.",
  },
  {
    id: "flush-headingThree",
    title: "How do you take payments?",
    content:
      "Absolutely! We prioritize security, employing state-of-the-art measures to protect your transactions and sensitive data.",
  },
];

export const accordionFullDataTwo = [
  {
    id: "flush-headingfour",
    title: "How do you take payments?",
    content:
      "Yes, PGmine offers uninterrupted service, allowing you to make transactions at any time that suits you.",
  },
  {
    id: "flush-headingfive",
    title: "What kind of data can I see in enigmastake?",
    content:
      "No, PGmine prides itself on transparent pricing. There are no hidden costs; we believe in clarity and trustworthiness.",
  },
  {
    id: "flush-headingsix",
    title: "What kind of data can I see in enigmastake?",
    content:
      "Our dedicated customer support team is available around the clock to assist you with any inquiries or concerns.",
  },
];
